import classNames from 'classnames';

const SpLogo = (props: React.SVGProps<SVGSVGElement>) => {
  const { className = '', ...attributes } = props;
  const classes = classNames('sp-logo', className);

  return (
    <svg
      viewBox="0 0 100 110"
      width={88.521}
      height={55}
      fill="none"
      className={classes}
      {...attributes}
    >
      <g clipPath="url(#clip0_2254_13263)">
        <path
          d="M71.6125 35.0729C71.836 34.5052 72.3833 34.1319 72.9923 34.1319C73.8171 34.1319 74.48 34.8085 74.48 35.6328C74.48 36.4572 73.8093 37.1338 72.9923 37.1338C72.5914 37.1338 72.2214 36.9782 71.9362 36.6905C71.7898 36.5427 71.6818 36.3716 71.6048 36.185H70.0708C70.0245 36.185 69.986 36.1694 69.9475 36.1305L64.4745 30.609H36.3467C36.1231 31.1767 35.5759 31.55 34.9669 31.55C34.1421 31.55 33.4792 30.8734 33.4792 30.049C33.4792 29.6446 33.6333 29.2714 33.9186 28.9836C34.1961 28.7036 34.5738 28.5403 34.9746 28.5403C35.5836 28.5403 36.1309 28.9214 36.3544 29.4813H64.8676C64.9139 29.4813 64.9524 29.4969 64.991 29.5358L70.4639 35.0651H71.6125V35.0729Z"
          fill="url(#paint0_linear_2254_13263)"
        />
        <path
          d="M49.5742 26.8916H59.1943L63.0562 22.9954C63.087 22.9643 63.1333 22.941 63.1795 22.941H71.6125C71.836 22.3811 72.3833 22 72.9923 22C73.8171 22 74.48 22.6766 74.48 23.5009C74.48 24.3331 73.8093 25.0019 72.9923 25.0019C72.3756 25.0019 71.836 24.6208 71.6125 24.0609H63.5649L59.703 27.9571C59.6722 27.9882 59.626 28.0115 59.5797 28.0115H49.5742C49.4972 28.1982 49.3892 28.3692 49.2428 28.517C48.9653 28.797 48.5876 28.9603 48.1867 28.9603C47.7859 28.9603 47.4159 28.8047 47.1307 28.517C46.8532 28.237 46.6913 27.856 46.6913 27.4516C46.6913 27.0472 46.8455 26.6739 47.1307 26.3861C47.4082 26.1062 47.7859 25.9429 48.1867 25.9429C48.5876 25.9429 48.9576 26.0984 49.2428 26.3861C49.3892 26.5339 49.4972 26.705 49.5742 26.8916Z"
          fill="url(#paint1_linear_2254_13263)"
        />
        <path
          d="M44.4559 85.6768H63.3414C63.4185 85.4902 63.5264 85.3191 63.6728 85.1714C63.9503 84.8914 64.3281 84.7281 64.7289 84.7281C65.5537 84.7281 66.2166 85.4047 66.2166 86.229C66.2166 87.0534 65.546 87.7299 64.7289 87.7299C64.1199 87.7299 63.5726 87.3489 63.3491 86.7889H44.4636C44.3865 86.9756 44.2786 87.1467 44.1321 87.2944C43.8546 87.5744 43.4769 87.7377 43.0761 87.7377C42.6753 87.7377 42.3053 87.5822 42.02 87.2944C41.7348 87.0145 41.5807 86.6334 41.5807 86.229C41.5807 85.3969 42.2513 84.7281 43.0684 84.7281C43.4692 84.7281 43.8392 84.8836 44.1244 85.1714C44.2709 85.3191 44.3788 85.4902 44.4559 85.6768Z"
          fill="url(#paint2_linear_2254_13263)"
        />
        <path
          d="M41.0565 81.3763L38.8827 84.3703C38.9907 84.5803 39.0523 84.8214 39.0523 85.0625C39.0523 85.8946 38.3817 86.5634 37.5646 86.5634C36.7475 86.5634 36.0769 85.8868 36.0769 85.0625C36.0769 84.2381 36.7475 83.5616 37.5646 83.5616C37.7265 83.5616 37.8884 83.5849 38.0425 83.6393L40.4398 80.3264C40.4707 80.2797 40.5246 80.2564 40.5786 80.2564H58.7857C58.8628 80.0697 58.9707 79.8987 59.1172 79.7509C59.3947 79.4709 59.7724 79.3076 60.1732 79.3076C60.5741 79.3076 60.9441 79.4631 61.2293 79.7509C61.5068 80.0309 61.6687 80.4119 61.6687 80.8163C61.6687 81.6484 60.998 82.3172 60.181 82.3172C59.572 82.3172 59.0247 81.9362 58.8012 81.3763H41.0565Z"
          fill="url(#paint3_linear_2254_13263)"
        />
        <path
          d="M47.3157 51.2176C47.5392 50.6499 48.0865 50.2766 48.6955 50.2766C49.5203 50.2766 50.1832 50.9532 50.1832 51.7776C50.1832 52.6019 49.5126 53.2785 48.6955 53.2785C48.2947 53.2785 47.9247 53.123 47.6394 52.8352C47.493 52.6875 47.3851 52.5164 47.308 52.3297H40.1855C40.1392 52.3297 40.1007 52.3142 40.0621 52.2753L32.5465 44.6929C32.5465 44.6929 32.4925 44.6151 32.4925 44.5684V41.1311C32.3075 41.0533 32.1379 40.9444 31.9915 40.7966C31.4133 40.2134 31.4133 39.2568 31.9915 38.6736C32.5696 38.0903 33.5177 38.0903 34.0958 38.6736C34.3733 38.9535 34.5352 39.3346 34.5352 39.739C34.5352 40.1434 34.3811 40.5167 34.0958 40.8044C33.9571 40.9522 33.7875 41.0611 33.5948 41.1388V44.1874L40.5709 51.2176H47.3157Z"
          fill="url(#paint4_linear_2254_13263)"
        />
        <path
          d="M58.0611 60.1143C58.2847 59.5466 58.832 59.1733 59.4409 59.1733C60.2657 59.1733 60.9287 59.8499 60.9287 60.6743C60.9287 61.4986 60.258 62.1752 59.4409 62.1752C59.0401 62.1752 58.6701 62.0197 58.3849 61.7319C58.2384 61.5842 58.1305 61.4131 58.0534 61.2264H51.4165C51.3703 61.2264 51.3317 61.2109 51.2932 61.172L45.8203 55.6504H38.744C38.6977 55.6504 38.6592 55.6349 38.6207 55.596L25.9866 42.842C25.9866 42.842 25.9327 42.7642 25.9327 42.7175V31.5655C25.7477 31.4878 25.5781 31.3789 25.4316 31.2311C24.8535 30.6479 24.8535 29.6913 25.4316 29.108C26.0098 28.5248 26.9579 28.5248 27.536 29.108C27.8135 29.388 27.9754 29.7691 27.9754 30.1735C27.9754 30.5779 27.8212 30.9512 27.536 31.2389C27.3973 31.3789 27.2277 31.4878 27.0427 31.5655V42.3287L39.1294 54.5306H46.2134C46.2596 54.5306 46.2982 54.5461 46.3367 54.585L51.8097 60.1066H58.0611V60.1143Z"
          fill="url(#paint5_linear_2254_13263)"
        />
        <path
          d="M61.3141 68.7233L65.4072 63.0384C65.2993 62.8207 65.2376 62.5874 65.2376 62.3463C65.2376 61.5142 65.9083 60.8454 66.7254 60.8454C67.5424 60.8454 68.2131 61.522 68.2131 62.3463C68.2131 63.1706 67.5424 63.8472 66.7254 63.8472C66.5635 63.8472 66.4016 63.8239 66.2474 63.7695L61.923 69.7732C61.8922 69.8199 61.8383 69.8432 61.7843 69.8432H60.2889C60.2118 70.0298 60.1039 70.2009 59.9574 70.3487C59.6799 70.6286 59.3022 70.792 58.9014 70.792C58.5005 70.792 58.1305 70.6364 57.8453 70.3487C57.5678 70.0687 57.4059 69.6876 57.4059 69.2833C57.4059 68.4511 58.0766 67.7823 58.8937 67.7823C59.5103 67.7823 60.0499 68.1634 60.2735 68.7233H61.3141Z"
          fill="url(#paint6_linear_2254_13263)"
        />
        <path
          d="M38.7594 41.03V42.422L42.6213 46.3182C42.6521 46.3493 42.6753 46.396 42.6753 46.4426V47.4536C43.238 47.6792 43.608 48.2313 43.608 48.8457C43.608 49.6778 42.9373 50.3466 42.1203 50.3466C41.2955 50.3466 40.6325 49.67 40.6325 48.8457C40.6325 48.2313 41.0102 47.6792 41.5653 47.4536V46.8315L37.7034 42.9353C37.7034 42.9353 37.6494 42.8575 37.6494 42.8109V41.03C37.4644 40.9522 37.2948 40.8433 37.1484 40.6955C36.8709 40.4078 36.709 40.0345 36.709 39.6301C36.709 39.2257 36.8631 38.8524 37.1484 38.5647C37.4336 38.2769 37.8036 38.1214 38.2044 38.1214C38.6052 38.1214 38.9752 38.2769 39.2605 38.5647C39.5457 38.8447 39.6998 39.2257 39.6998 39.6301C39.6998 40.0345 39.5457 40.4078 39.2605 40.6955C39.114 40.8433 38.9444 40.9522 38.7594 41.03Z"
          fill="url(#paint7_linear_2254_13263)"
        />
        <path
          d="M69.3924 68.039V69.8199C69.3924 69.8665 69.377 69.9054 69.3385 69.9443L65.4766 73.8405V74.4626C66.0393 74.6882 66.4093 75.2403 66.4093 75.8547C66.4093 76.6868 65.7387 77.3556 64.9216 77.3556C64.0968 77.3556 63.4339 76.679 63.4339 75.8547C63.4339 75.2403 63.8116 74.6882 64.3666 74.4626V73.4516C64.3666 73.405 64.382 73.3661 64.4206 73.3272L68.2824 69.431V68.039C68.0974 67.9612 67.9279 67.8523 67.7814 67.7046C67.5039 67.4168 67.342 67.0435 67.342 66.6391C67.342 66.2347 67.4962 65.8614 67.7814 65.5737C68.0589 65.2937 68.4366 65.1304 68.8374 65.1304C69.2383 65.1304 69.6083 65.2859 69.8935 65.5737C70.171 65.8614 70.3329 66.2347 70.3329 66.6391C70.3329 67.0435 70.1787 67.4168 69.8935 67.7046C69.747 67.8523 69.5774 67.9612 69.3924 68.039Z"
          fill="url(#paint8_linear_2254_13263)"
        />
        <path
          d="M56.3961 55.4793H65.916C65.916 55.4793 65.9854 55.4871 66.0162 55.5105L74.4106 61.5686C74.4568 61.5997 74.48 61.6542 74.48 61.7086V68.1712C74.48 68.2178 74.4646 68.2567 74.4337 68.2878L70.7337 72.3784V79.5876C71.2964 79.8131 71.6664 80.3653 71.6664 80.9796C71.6664 81.8118 70.9958 82.4806 70.1787 82.4806C69.7779 82.4806 69.4079 82.325 69.1227 82.0373C68.8452 81.7495 68.6833 81.3763 68.6833 80.9719C68.6833 80.5675 68.8374 80.1942 69.1227 79.9064C69.2691 79.7587 69.4387 79.6498 69.6237 79.572V71.9974C69.6237 71.9507 69.6391 71.9118 69.67 71.8807L73.37 67.7901V62.1752L65.5922 56.5759H56.3807C56.3036 56.7625 56.1957 56.9336 56.0493 57.0814C55.7641 57.3613 55.3941 57.5247 54.9932 57.5247C54.5924 57.5247 54.2224 57.3691 53.9372 57.0814C53.6597 56.7936 53.4978 56.4203 53.4978 56.0159C53.4978 55.6115 53.652 55.2383 53.9372 54.9505C54.2224 54.6628 54.5924 54.5072 54.9932 54.5072C55.3941 54.5072 55.7641 54.6628 56.0493 54.9505C56.1957 55.0983 56.3036 55.2694 56.3807 55.456L56.3961 55.4793Z"
          fill="url(#paint9_linear_2254_13263)"
        />
        <path
          d="M58.9553 73.0006C58.1305 73.0006 57.4676 73.6772 57.4676 74.5015C57.4676 75.3259 58.1382 76.0024 58.9553 76.0024C59.1249 76.0024 59.2868 75.9713 59.441 75.9169L67.5193 85.0236C67.5193 85.0236 67.5964 85.078 67.6504 85.078H71.6048C71.8283 85.6457 72.3756 86.019 72.9846 86.019C73.8093 86.019 74.4723 85.3424 74.4723 84.5181C74.4723 83.6938 73.8016 83.0172 72.9846 83.0172C72.3679 83.0172 71.8283 83.3982 71.6048 83.9582H68.0666L60.2735 75.1781C60.3814 74.9681 60.4353 74.7348 60.4353 74.486C60.4353 73.6538 59.7647 72.985 58.9476 72.985L58.9553 73.0006Z"
          fill="url(#paint10_linear_2254_13263)"
        />
        <path
          d="M29.9873 80.7697L27.8135 83.7637C27.9214 83.9737 27.9831 84.2148 27.9831 84.4559C27.9831 85.288 27.3125 85.9568 26.4954 85.9568C25.6783 85.9568 25.0077 85.2802 25.0077 84.4559C25.0077 83.6315 25.6783 82.955 26.4954 82.955C26.6573 82.955 26.8191 82.9783 26.9733 83.0327L29.3706 79.7198C29.4015 79.6731 29.4554 79.6498 29.5094 79.6498H32.5156L30.0335 76.1813C29.9179 76.2124 29.7946 76.228 29.6712 76.228C28.8464 76.228 28.1835 75.5514 28.1835 74.727C28.1835 73.9027 28.8542 73.2261 29.6712 73.2261C30.4883 73.2261 31.159 73.9027 31.159 74.727C31.159 75.0148 31.0742 75.287 30.9277 75.5281L33.8723 79.6498H37.2254L41.28 75.9169C41.28 75.9169 41.3494 75.8702 41.3957 75.8702H51.1082C51.1853 75.6836 51.2932 75.5125 51.4397 75.3647C51.7172 75.0848 52.0949 74.9215 52.4957 74.9215C52.8965 74.9215 53.2666 75.077 53.5518 75.3647C53.8293 75.6447 53.9911 76.0258 53.9911 76.4302C53.9911 77.2623 53.3205 77.9311 52.5034 77.9311C51.8868 77.9311 51.3472 77.55 51.1236 76.9901H41.7734L37.7188 80.723C37.6879 80.7541 37.6494 80.7697 37.6032 80.7697H29.9873Z"
          fill="url(#paint11_linear_2254_13263)"
        />
        <path
          d="M71.6125 39.6612C71.836 39.0935 72.3833 38.7202 72.9923 38.7202C73.8171 38.7202 74.48 39.3968 74.48 40.2212C74.48 41.0455 73.8093 41.7221 72.9923 41.7221C72.3756 41.7221 71.836 41.341 71.6125 40.7811H66.9335C66.8795 40.7811 66.8333 40.7578 66.8024 40.7189L61.1445 33.7664H41.3417L38.5436 37.2115C38.5127 37.2504 38.4665 37.2737 38.4125 37.2737H31.051C30.8275 37.8414 30.2802 38.2147 29.6712 38.2147C28.8464 38.2147 28.1835 37.5381 28.1835 36.7138C28.1835 35.8817 28.8542 35.2129 29.6712 35.2129C30.2802 35.2129 30.8275 35.5939 31.051 36.1539H37.9654L40.7636 32.7087C40.7944 32.6698 40.8407 32.6465 40.8946 32.6465H61.5839C61.6378 32.6465 61.6841 32.6698 61.7149 32.7087L67.3652 39.6612H71.6125Z"
          fill="url(#paint12_linear_2254_13263)"
        />
        <path
          d="M63.4416 40.7966L60.6512 37.1571H43.978C43.7544 37.7248 43.2071 38.0981 42.5982 38.0981C41.7734 38.0981 41.1105 37.4215 41.1105 36.5971C41.1105 35.7728 41.7811 35.0962 42.5982 35.0962C43.2071 35.0962 43.7544 35.4773 43.978 36.0372H61.1137C61.1676 36.0372 61.2139 36.0605 61.2524 36.1072L64.4128 40.2367C64.4822 40.2289 64.5439 40.2212 64.6133 40.2212C65.0141 40.2212 65.3841 40.3767 65.6693 40.6644C65.9468 40.9444 66.1087 41.3255 66.1087 41.7299C66.1087 42.562 65.4381 43.2308 64.621 43.2308C63.7962 43.2308 63.1333 42.5542 63.1333 41.7299C63.1333 41.3877 63.2412 41.0688 63.4493 40.8044L63.4416 40.7966Z"
          fill="url(#paint13_linear_2254_13263)"
        />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1 30.5909V79.4338L49.5 108.534V96H50.5V108.534L99 79.4338V30.8965L82.7627 40.9254L82.2373 40.0746L98.306 30.1498L50 1.16619L1.94097 30.0016L14.7664 38.0769L14.2336 38.9231L1 30.5909ZM0 30L50 0L100 30V80L50 110L0 80V30Z"
        fill="url(#paint14_linear_2254_13263)"
      />
      <path
        d="M81 40.5C81 39.6716 81.6716 39 82.5 39C83.3284 39 84 39.6716 84 40.5C84 41.3284 83.3284 42 82.5 42C81.6716 42 81 41.3284 81 40.5Z"
        fill="#83BF41"
      />
      <path
        d="M13 38.5C13 37.6716 13.6716 37 14.5 37C15.3284 37 16 37.6716 16 38.5C16 39.3284 15.3284 40 14.5 40C13.6716 40 13 39.3284 13 38.5Z"
        fill="#46B08C"
      />
      <path
        d="M48.5 95.5C48.5 94.6716 49.1716 94 50 94C50.8284 94 51.5 94.6716 51.5 95.5C51.5 96.3284 50.8284 97 50 97C49.1716 97 48.5 96.3284 48.5 95.5Z"
        fill="#16A4C7"
      />
      <defs>
        <linearGradient
          id="paint0_linear_2254_13263"
          x1="36.1809"
          y1="78.6002"
          x2="74.2766"
          y2="40.7777"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#009FE3" />
          <stop offset="1" stopColor="#AFCA0B" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_2254_13263"
          x1="36.1809"
          y1="78.6002"
          x2="74.2766"
          y2="40.7777"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#009FE3" />
          <stop offset="1" stopColor="#AFCA0B" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_2254_13263"
          x1="36.1809"
          y1="78.6002"
          x2="74.2766"
          y2="40.7777"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#009FE3" />
          <stop offset="1" stopColor="#AFCA0B" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_2254_13263"
          x1="36.1809"
          y1="78.6002"
          x2="74.2766"
          y2="40.7777"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#009FE3" />
          <stop offset="1" stopColor="#AFCA0B" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_2254_13263"
          x1="36.1809"
          y1="78.6002"
          x2="74.2766"
          y2="40.7777"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#009FE3" />
          <stop offset="1" stopColor="#AFCA0B" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_2254_13263"
          x1="36.1809"
          y1="78.6002"
          x2="74.2766"
          y2="40.7777"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#009FE3" />
          <stop offset="1" stopColor="#AFCA0B" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_2254_13263"
          x1="36.1809"
          y1="78.6002"
          x2="74.2766"
          y2="40.7777"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#009FE3" />
          <stop offset="1" stopColor="#AFCA0B" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_2254_13263"
          x1="36.1809"
          y1="78.6002"
          x2="74.2766"
          y2="40.7777"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#009FE3" />
          <stop offset="1" stopColor="#AFCA0B" />
        </linearGradient>
        <linearGradient
          id="paint8_linear_2254_13263"
          x1="36.1809"
          y1="78.6002"
          x2="74.2766"
          y2="40.7777"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#009FE3" />
          <stop offset="1" stopColor="#AFCA0B" />
        </linearGradient>
        <linearGradient
          id="paint9_linear_2254_13263"
          x1="36.1809"
          y1="78.6002"
          x2="74.2766"
          y2="40.7777"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#009FE3" />
          <stop offset="1" stopColor="#AFCA0B" />
        </linearGradient>
        <linearGradient
          id="paint10_linear_2254_13263"
          x1="36.1809"
          y1="78.6002"
          x2="74.2766"
          y2="40.7777"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#009FE3" />
          <stop offset="1" stopColor="#AFCA0B" />
        </linearGradient>
        <linearGradient
          id="paint11_linear_2254_13263"
          x1="36.1809"
          y1="78.6002"
          x2="74.2766"
          y2="40.7777"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#009FE3" />
          <stop offset="1" stopColor="#AFCA0B" />
        </linearGradient>
        <linearGradient
          id="paint12_linear_2254_13263"
          x1="36.1809"
          y1="78.6002"
          x2="74.2766"
          y2="40.7777"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#009FE3" />
          <stop offset="1" stopColor="#AFCA0B" />
        </linearGradient>
        <linearGradient
          id="paint13_linear_2254_13263"
          x1="36.1809"
          y1="78.6002"
          x2="74.2766"
          y2="40.7777"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#009FE3" />
          <stop offset="1" stopColor="#AFCA0B" />
        </linearGradient>
        <linearGradient
          id="paint14_linear_2254_13263"
          x1="17.5814"
          y1="90.524"
          x2="80.2989"
          y2="15.3197"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#009FE3" />
          <stop offset="1" stopColor="#AFCA0B" />
        </linearGradient>
        <clipPath id="clip0_2254_13263">
          <rect
            width="49.48"
            height="65.7377"
            fill="white"
            transform="translate(25 22)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SpLogo;
