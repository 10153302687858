import classNames from 'classnames';

const SpBrandName = (props: React.SVGProps<SVGSVGElement>) => {
  const { className = '', ...attributes } = props;
  const classes = classNames('sp-brand-name', className);

  return (
    <svg viewBox="0 0 617 110" fill="none" className={classes} {...attributes}>
      <path
        d="M28.665 85.41C22.191 85.41 16.692 84.24 12.168 81.9C7.644 79.56 3.588 76.128 0 71.604L9.126 62.478C11.388 65.754 14.079 68.328 17.199 70.2C20.319 71.994 24.297 72.891 29.133 72.891C33.657 72.891 37.245 71.955 39.897 70.083C42.627 68.211 43.992 65.637 43.992 62.361C43.992 59.631 43.29 57.408 41.886 55.692C40.482 53.976 38.61 52.572 36.27 51.48C34.008 50.31 31.473 49.296 28.665 48.438C25.935 47.502 23.166 46.488 20.358 45.396C17.628 44.226 15.093 42.783 12.753 41.067C10.491 39.351 8.658 37.128 7.254 34.398C5.85 31.668 5.148 28.197 5.148 23.985C5.148 19.149 6.318 15.054 8.658 11.7C11.076 8.268 14.313 5.655 18.369 3.861C22.425 2.067 26.949 1.17 31.941 1.17C37.323 1.17 42.12 2.223 46.332 4.329C50.622 6.435 54.093 9.126 56.745 12.402L47.619 21.528C45.279 18.876 42.822 16.926 40.248 15.678C37.752 14.352 34.866 13.689 31.59 13.689C27.534 13.689 24.336 14.508 21.996 16.146C19.734 17.784 18.603 20.085 18.603 23.049C18.603 25.545 19.305 27.573 20.709 29.133C22.113 30.615 23.946 31.902 26.208 32.994C28.548 34.008 31.083 34.983 33.813 35.919C36.621 36.855 39.39 37.908 42.12 39.078C44.928 40.248 47.463 41.769 49.725 43.641C52.065 45.435 53.937 47.775 55.341 50.661C56.745 53.547 57.447 57.174 57.447 61.542C57.447 68.874 54.834 74.685 49.608 78.975C44.46 83.265 37.479 85.41 28.665 85.41Z"
        fill="white"
      />
      <path
        d="M64.5447 84.24V28.08H77.4147V84.24H64.5447ZM70.9797 17.784C68.7957 17.784 66.9627 17.043 65.4807 15.561C64.0767 14.079 63.3747 12.246 63.3747 10.062C63.3747 7.878 64.0767 6.045 65.4807 4.563C66.9627 3.081 68.7957 2.34 70.9797 2.34C73.2417 2.34 75.0747 3.081 76.4787 4.563C77.8827 6.045 78.5847 7.878 78.5847 10.062C78.5847 12.246 77.8827 14.079 76.4787 15.561C75.0747 17.043 73.2417 17.784 70.9797 17.784Z"
        fill="white"
      />
      <path
        d="M87.1696 84.24V28.08H100.04V84.24H87.1696ZM124.493 84.24V50.778C124.493 47.034 123.323 44.148 120.983 42.12C118.643 40.014 115.757 38.961 112.325 38.961C109.985 38.961 107.879 39.429 106.007 40.365C104.213 41.301 102.77 42.666 101.678 44.46C100.586 46.176 100.04 48.282 100.04 50.778L95.0086 47.97C95.0086 43.68 95.9446 39.975 97.8166 36.855C99.6886 33.735 102.224 31.317 105.422 29.601C108.62 27.807 112.208 26.91 116.186 26.91C120.164 26.91 123.752 27.768 126.95 29.484C130.148 31.2 132.683 33.618 134.555 36.738C136.427 39.858 137.363 43.602 137.363 47.97V84.24H124.493ZM161.816 84.24V50.778C161.816 47.034 160.646 44.148 158.306 42.12C155.966 40.014 153.08 38.961 149.648 38.961C147.386 38.961 145.319 39.429 143.447 40.365C141.575 41.301 140.093 42.666 139.001 44.46C137.909 46.176 137.363 48.282 137.363 50.778L130.109 47.97C130.499 43.68 131.747 39.975 133.853 36.855C135.959 33.735 138.65 31.317 141.926 29.601C145.28 27.807 148.946 26.91 152.924 26.91C156.98 26.91 160.646 27.768 163.922 29.484C167.198 31.2 169.811 33.618 171.761 36.738C173.711 39.858 174.686 43.602 174.686 47.97V84.24H161.816Z"
        fill="white"
      />
      <path
        d="M213.334 85.41C209.044 85.41 205.222 84.513 201.868 82.719C198.514 80.847 195.862 78.312 193.912 75.114C192.04 71.916 191.104 68.289 191.104 64.233V48.087C191.104 44.031 192.079 40.404 194.029 37.206C195.979 34.008 198.631 31.512 201.985 29.718C205.339 27.846 209.122 26.91 213.334 26.91C218.404 26.91 222.967 28.236 227.023 30.888C231.157 33.462 234.394 36.972 236.734 41.418C239.152 45.786 240.361 50.739 240.361 56.277C240.361 61.737 239.152 66.69 236.734 71.136C234.394 75.504 231.157 78.975 227.023 81.549C222.967 84.123 218.404 85.41 213.334 85.41ZM183.265 107.64V28.08H196.135V43.173L193.912 56.862L196.135 70.434V107.64H183.265ZM211.228 73.242C214.348 73.242 217.078 72.54 219.418 71.136C221.836 69.654 223.708 67.626 225.034 65.052C226.438 62.478 227.14 59.514 227.14 56.16C227.14 52.806 226.438 49.842 225.034 47.268C223.708 44.694 221.836 42.705 219.418 41.301C217.078 39.819 214.348 39.078 211.228 39.078C208.108 39.078 205.339 39.819 202.921 41.301C200.503 42.705 198.631 44.694 197.305 47.268C195.979 49.842 195.316 52.806 195.316 56.16C195.316 59.514 195.979 62.478 197.305 65.052C198.631 67.626 200.503 69.654 202.921 71.136C205.339 72.54 208.108 73.242 211.228 73.242Z"
        fill="white"
      />
      <path d="M246.454 84.24V0H259.324V84.24H246.454Z" fill="white" />
      <path
        d="M295.058 85.41C289.442 85.41 284.372 84.162 279.848 81.666C275.402 79.092 271.853 75.582 269.201 71.136C266.627 66.69 265.34 61.698 265.34 56.16C265.34 50.622 266.627 45.669 269.201 41.301C271.775 36.855 275.246 33.345 279.614 30.771C284.06 28.197 288.974 26.91 294.356 26.91C299.582 26.91 304.184 28.119 308.162 30.537C312.218 32.955 315.377 36.27 317.639 40.482C319.979 44.694 321.149 49.491 321.149 54.873C321.149 55.809 321.071 56.784 320.915 57.798C320.837 58.734 320.681 59.787 320.447 60.957H274.349V50.427H313.778L308.981 54.639C308.825 51.207 308.162 48.321 306.992 45.981C305.822 43.641 304.145 41.847 301.961 40.599C299.855 39.351 297.242 38.727 294.122 38.727C290.846 38.727 287.999 39.429 285.581 40.833C283.163 42.237 281.291 44.226 279.965 46.8C278.639 49.296 277.976 52.299 277.976 55.809C277.976 59.319 278.678 62.4 280.082 65.052C281.486 67.704 283.475 69.771 286.049 71.253C288.623 72.657 291.587 73.359 294.941 73.359C297.827 73.359 300.479 72.852 302.897 71.838C305.393 70.824 307.499 69.342 309.215 67.392L317.405 75.699C314.675 78.897 311.36 81.315 307.46 82.953C303.56 84.591 299.426 85.41 295.058 85.41Z"
        fill="white"
      />
      <path
        d="M335.444 51.948V44.343H359.195C362.471 44.343 365.396 43.68 367.97 42.354C370.622 41.028 372.69 39.156 374.172 36.738C375.732 34.242 376.512 31.278 376.512 27.846C376.512 24.414 375.732 21.489 374.172 19.071C372.69 16.575 370.622 14.664 367.97 13.338C365.396 11.934 362.471 11.232 359.195 11.232H335.444V3.744H359.78C364.616 3.744 368.945 4.758 372.767 6.786C376.589 8.736 379.592 11.505 381.776 15.093C384.038 18.681 385.169 22.932 385.169 27.846C385.169 32.682 384.038 36.933 381.776 40.599C379.592 44.187 376.589 46.995 372.767 49.023C368.945 50.973 364.616 51.948 359.78 51.948H335.444ZM329.945 84.24V3.744H338.487V84.24H329.945Z"
        fill="white"
      />
      <path
        d="M415.838 85.41C410.534 85.41 405.698 84.123 401.33 81.549C397.04 78.975 393.608 75.504 391.034 71.136C388.538 66.768 387.29 61.893 387.29 56.511C387.29 51.207 388.538 46.41 391.034 42.12C393.608 37.83 397.04 34.437 401.33 31.941C405.698 29.367 410.534 28.08 415.838 28.08C421.22 28.08 426.056 29.328 430.346 31.824C434.636 34.32 438.029 37.752 440.525 42.12C443.099 46.41 444.386 51.207 444.386 56.511C444.386 61.971 443.099 66.885 440.525 71.253C438.029 75.543 434.636 78.975 430.346 81.549C426.056 84.123 421.22 85.41 415.838 85.41ZM415.838 77.454C419.738 77.454 423.17 76.557 426.134 74.763C429.176 72.969 431.555 70.473 433.271 67.275C435.065 64.077 435.962 60.489 435.962 56.511C435.962 52.533 435.065 49.023 433.271 45.981C431.555 42.861 429.176 40.404 426.134 38.61C423.17 36.816 419.738 35.919 415.838 35.919C412.016 35.919 408.584 36.816 405.542 38.61C402.5 40.404 400.121 42.861 398.405 45.981C396.689 49.023 395.831 52.533 395.831 56.511C395.831 60.567 396.689 64.194 398.405 67.392C400.121 70.512 402.5 72.969 405.542 74.763C408.584 76.557 412.016 77.454 415.838 77.454Z"
        fill="white"
      />
      <path
        d="M476.135 85.41C471.065 85.41 466.502 84.162 462.446 81.666C458.39 79.17 455.153 75.777 452.735 71.487C450.395 67.119 449.225 62.244 449.225 56.862C449.225 51.402 450.395 46.527 452.735 42.237C455.153 37.869 458.39 34.437 462.446 31.941C466.502 29.367 471.065 28.08 476.135 28.08C480.269 28.08 484.013 28.899 487.367 30.537C490.721 32.175 493.49 34.515 495.674 37.557C497.936 40.521 499.262 44.031 499.652 48.087V65.286C499.184 69.264 497.858 72.774 495.674 75.816C493.568 78.78 490.838 81.12 487.484 82.836C484.13 84.552 480.347 85.41 476.135 85.41ZM477.539 77.571C481.361 77.571 484.715 76.713 487.601 74.997C490.487 73.203 492.749 70.746 494.387 67.626C496.025 64.428 496.844 60.801 496.844 56.745C496.844 52.611 495.986 48.984 494.27 45.864C492.632 42.744 490.37 40.287 487.484 38.493C484.598 36.699 481.244 35.802 477.422 35.802C473.6 35.802 470.207 36.699 467.243 38.493C464.279 40.287 461.939 42.783 460.223 45.981C458.585 49.101 457.766 52.689 457.766 56.745C457.766 60.801 458.585 64.428 460.223 67.626C461.939 70.746 464.279 73.203 467.243 74.997C470.285 76.713 473.717 77.571 477.539 77.571ZM504.566 84.24H496.376V69.498L498.014 55.926L496.376 42.705V1.404H504.566V84.24Z"
        fill="white"
      />
      <path
        d="M525.154 85.41C523.204 85.41 521.605 84.747 520.357 83.421C519.109 82.095 518.485 80.496 518.485 78.624C518.485 76.752 519.109 75.192 520.357 73.944C521.605 72.618 523.204 71.955 525.154 71.955C527.026 71.955 528.586 72.618 529.834 73.944C531.082 75.192 531.706 76.752 531.706 78.624C531.706 80.496 531.082 82.095 529.834 83.421C528.586 84.747 527.026 85.41 525.154 85.41Z"
        fill="white"
      />
      <path
        d="M566.876 85.41C561.65 85.41 556.97 84.162 552.836 81.666C548.702 79.092 545.426 75.66 543.008 71.37C540.668 67.08 539.498 62.244 539.498 56.862C539.498 51.402 540.668 46.527 543.008 42.237C545.426 37.869 548.702 34.437 552.836 31.941C556.97 29.367 561.611 28.08 566.759 28.08C570.971 28.08 574.715 28.938 577.991 30.654C581.345 32.292 584.036 34.632 586.064 37.674C588.17 40.638 589.457 44.109 589.925 48.087V65.286C589.457 69.264 588.17 72.774 586.064 75.816C584.036 78.858 581.345 81.237 577.991 82.953C574.715 84.591 571.01 85.41 566.876 85.41ZM568.163 77.571C573.857 77.571 578.42 75.66 581.852 71.838C585.362 68.016 587.117 62.985 587.117 56.745C587.117 52.611 586.298 48.984 584.66 45.864C583.1 42.666 580.877 40.209 577.991 38.493C575.183 36.699 571.868 35.802 568.046 35.802C564.146 35.802 560.675 36.699 557.633 38.493C554.669 40.287 552.29 42.783 550.496 45.981C548.78 49.101 547.922 52.689 547.922 56.745C547.922 60.801 548.78 64.389 550.496 67.509C552.29 70.629 554.708 73.086 557.75 74.88C560.792 76.674 564.263 77.571 568.163 77.571ZM586.649 84.24V69.498L588.287 55.926L586.649 42.705V29.25H594.839V84.24H586.649Z"
        fill="white"
      />
      <path
        d="M606.768 84.24V29.25H615.075V84.24H606.768ZM610.863 17.316C609.303 17.316 607.977 16.77 606.885 15.678C605.793 14.508 605.247 13.143 605.247 11.583C605.247 9.945 605.793 8.58 606.885 7.488C607.977 6.396 609.303 5.85 610.863 5.85C612.579 5.85 613.944 6.396 614.958 7.488C616.05 8.58 616.596 9.945 616.596 11.583C616.596 13.143 616.05 14.508 614.958 15.678C613.944 16.77 612.579 17.316 610.863 17.316Z"
        fill="white"
      />
    </svg>
  );
};

export default SpBrandName;
